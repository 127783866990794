import axios from '../axios';

export const all = (lang) => {
  return axios.get(`/product-subcategories?lang=${lang}`);
};

export const show = (slug, lang) => {
  return axios.get(`/product-subcategories/${slug}?lang=${lang}`);
};

export const edit = (data) => {
  return axios.post(`/product-subcategories/${data.slug}`, data);
};
