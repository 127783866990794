<template>
  <validation-observer ref="formObserver">
    <b-form @submit.prevent="validationForm" class="form-container">
      <b-col>
        <h4 class="mb-2">Konfiguracja</h4>
        <b-card>
          <b-form-group label="Język" label-for="v-language-select">
            <validation-provider #default="{ errors }" name="Język" rules="required">
              <v-select
                id="v-language-select"
                label="value"
                placeholder="Wybierz język"
                v-model="lang"
                :clearable="false"
                :deselectFromDropdown="false"
                :options="languages"
                @input="fetchByLocale" />
              <small class="text-danger" :class="errors[0] ? 'error-shown' : ''">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Nazwa" label-for="v-name-main">
            <validation-provider name="Nazwa" rules="required|min:2|max:100" v-slot="{ errors }">
              <b-form-input id="v-name-main" placeholder="Nazwa" v-model="form.name" />
              <small class="text-danger" :class="errors[0] ? 'error-shown' : ''">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Slug (pozostaw pusty, aby wygenerować automatycznie)" label-for="v-slug-main">
            <validation-provider name="Slug głównej kategorii" rules="min:2|max:100" v-slot="{ errors }">
              <b-form-input id="v-slug-main" placeholder="Slug" v-model="form.slug" />
              <small class="text-danger" :class="errors[0] ? 'error-shown' : ''">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-card>

        <h4 class="mb-2 mt-4">Dane podkategorii</h4>

        <b-card v-for="(subgroup, index) in form.navigation_subgroups" :key="index" class="mb-1">
          <b-card-title class="d-flex justify-content-between align-items-center">
            <div>
              <h5 class="m-0">{{ subgroup.name ? subgroup.name : `Grupa ${index + 1}` }}</h5>
            </div>
            <div class="d-flex">
              <span :class="`sub-${index}`" v-b-toggle="`sub-${index}`">
                <feather-icon class="edit-icon" icon="EditIcon" />
              </span>
              <span>
                <feather-icon class="delete-icon ml-1" icon="TrashIcon" @click="removeGroup(index)" />
              </span>
            </div>
          </b-card-title>
          <div class="mt-2">
            <b-form-group label="Nazwa grupy" :label-for="`v-group-name-${index}`">
              <validation-provider name="Nazwa grupy" rules="required|min:2|max:100" v-slot="{ errors }">
                <b-form-input :id="`v-group-name-${index}`" placeholder="Nazwa grupy" v-model="subgroup.name" />
                <small class="text-danger" :class="errors[0] ? 'error-shown' : ''">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Slug (pozostaw pusty, aby wygenerować automatycznie)"
              :label-for="`v-group-slug-${index}`">
              <validation-provider :name="`Slug grupy ${index + 1}`" rules="min:2|max:100" v-slot="{ errors }">
                <b-form-input :id="`v-group-slug-${index}`" placeholder="Slug" v-model="subgroup.slug" />
                <small class="text-danger" :class="errors[0] ? 'error-shown' : ''">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-collapse :id="`sub-${index}`">
              <div v-for="(navEl, subIdx) in subgroup.navigation_elements" :key="`sub-${subIdx}`" class="pl-2 mt-5">
                <b-card-title class="d-flex justify-content-between align-items-center mt-3">
                  <div>
                    <h6 class="m-0">
                      {{ subgroup.name ? subgroup.name : `nazwa grupy` }},
                      {{ navEl.name ? navEl.name : 'nazwa kategorii' }}
                    </h6>
                  </div>
                  <div>
                    <feather-icon class="delete-icon" icon="TrashIcon" @click="removeCategory(index, subIdx)" />
                  </div>
                </b-card-title>

                <div class="mt-2">
                  <b-form-group label="Nazwa" :label-for="`v-subgroup-name-${index}-${subIdx}`">
                    <validation-provider
                      :name="`Nazwa grupy ${index + 1}, kategorii ${subIdx + 1}`"
                      rules="required|min:2|max:100"
                      v-slot="{ errors }">
                      <b-form-input
                        :id="`v-subgroup-name-${index}-${subIdx}`"
                        placeholder="Nazwa"
                        v-model="navEl.name" />
                      <small
                        class="text-danger"
                        :class="errors[0] ? 'error-shown' : ''"
                        :data-collapse-id="`sub-${index}`">
                        {{ errors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                    label="Slug (pozostaw pusty, aby wygenerować automatycznie)"
                    :label-for="`v-subgroup-slug-${index}-${subIdx}`">
                    <validation-provider
                      :name="`Slug grupy ${index + 1}, kateogrii ${subIdx + 1}`"
                      rules="min:2|max:100"
                      v-slot="{ errors }">
                      <b-form-input
                        :id="`v-subgroup-slug-${index}-${subIdx}`"
                        placeholder="Slug"
                        v-model="navEl.slug" />
                      <small
                        class="text-danger"
                        :data-collapse-id="`sub-${index}`"
                        :class="errors[0] ? 'error-shown' : ''"
                        >{{ errors[0] }}</small
                      >
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Kategorie" label-for="v-category-select">
                    <validation-provider
                      :name="`Kategoria ${index + 1}-${subIdx + 1}`"
                      rules="required"
                      v-slot="{ errors }">
                      <v-select
                        label="name"
                        :name="`Kategoria ${index + 1}-${subIdx + 1}`"
                        placeholder="Wybierz kategorię"
                        v-model="navEl.categories"
                        :id="`v-subgroup-categories-${index}-${subIdx}`"
                        :clearable="false"
                        :closeOnSelect="false"
                        :multiple="true"
                        :deselectFromDropdown="false"
                        :options="subcategories" />
                      <small
                        class="text-danger"
                        :class="errors[0] ? 'error-shown' : ''"
                        :data-collapse-id="`sub-${index}`">
                        {{ errors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <b-button class="mt-2 ml-2" size="sm" variant="info" @click="addCategory(index)">
                  Dodaj kategorię
                </b-button>
              </div>
            </b-collapse>
          </div>
        </b-card>
        <div class="d-flex mt-2 justify-content-end">
          <b-button variant="info" @click="addGroup">Dodaj grupę</b-button>
          <b-button class="ml-1" variant="success" type="submit">Zapisz</b-button>
        </div>
      </b-col>
    </b-form>
  </validation-observer>
</template>

<script>
import NotificationMixin from '@/mixins/NotificationMixin';

import { getLanguages } from '@/constants/languages';

export default {
  mixins: [NotificationMixin],
  props: {
    navigationGroup: {
      type: Object,
      required: true,
    },
    productSubcategories: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      form: this.navigationGroup,
      lang: {
        lang: 'pl',
        value: 'Polski',
      },
      languages: getLanguages(),
      subcategories: this.productSubcategories,
    };
  },

  watch: {
    navigationGroup: {
      handler() {
        this.form = this.navigationGroup;
      },
      deep: true,
    },
    productSubcategories: {
      handler() {
        this.subcategories = this.productSubcategories;
      },
      deep: true,
    },
  },

  methods: {
    removeGroup(index) {
      this.form.navigation_subgroups.splice(index, 1);
    },
    removeCategory(index, subIdx) {
      this.form.navigation_subgroups[index].navigation_elements.splice(subIdx, 1);
    },
    addCategory(index) {
      this.navigationGroup.navigation_subgroups[index].navigation_elements.push({
        name: '',
        categories: [],
        slug: '',
      });
    },
    addGroup() {
      this.navigationGroup.navigation_subgroups.push({
        name: '',
        navigation_elements: [],
        slug: '',
      });
    },
    fetchByLocale() {
      this.$emit('fetchByLocale', this.lang);
    },
    onSubmit() {
      this.$emit('onSubmit', {
        ...this.form,
        lang: this.lang.lang,
      });
    },
    validationForm() {
      this.$refs.formObserver.validate().then((success) => {
        if (success) {
          return this.onSubmit();
        }

        const elementToScrollTo = document.querySelector('.error-shown');

        if (elementToScrollTo) {
          const collapseElement = document.querySelector(`#${elementToScrollTo.dataset.collapseId}`);

          if (collapseElement && !collapseElement.classList.contains('show')) {
            const collapseBtn = document.querySelector(`.${elementToScrollTo.dataset.collapseId}`);
            collapseBtn.click();

            return setTimeout(() => {
              elementToScrollTo.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 500);
          }
        }

        elementToScrollTo.scrollIntoView({ behavior: 'smooth', block: 'center' });
        this.showFormValidationNotification();
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.edit-icon:hover {
  cursor: pointer;
  color: green;
}

.delete-icon:hover {
  cursor: pointer;
  color: red;
}
</style>
