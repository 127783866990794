var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formObserver"},[_c('b-form',{staticClass:"form-container",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-col',[_c('h4',{staticClass:"mb-2"},[_vm._v("Konfiguracja")]),_c('b-card',[_c('b-form-group',{attrs:{"label":"Język","label-for":"v-language-select"}},[_c('validation-provider',{attrs:{"name":"Język","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"v-language-select","label":"value","placeholder":"Wybierz język","clearable":false,"deselectFromDropdown":false,"options":_vm.languages},on:{"input":_vm.fetchByLocale},model:{value:(_vm.lang),callback:function ($$v) {_vm.lang=$$v},expression:"lang"}}),_c('small',{staticClass:"text-danger",class:errors[0] ? 'error-shown' : ''},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nazwa","label-for":"v-name-main"}},[_c('validation-provider',{attrs:{"name":"Nazwa","rules":"required|min:2|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-name-main","placeholder":"Nazwa"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger",class:errors[0] ? 'error-shown' : ''},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Slug (pozostaw pusty, aby wygenerować automatycznie)","label-for":"v-slug-main"}},[_c('validation-provider',{attrs:{"name":"Slug głównej kategorii","rules":"min:2|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-slug-main","placeholder":"Slug"},model:{value:(_vm.form.slug),callback:function ($$v) {_vm.$set(_vm.form, "slug", $$v)},expression:"form.slug"}}),_c('small',{staticClass:"text-danger",class:errors[0] ? 'error-shown' : ''},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('h4',{staticClass:"mb-2 mt-4"},[_vm._v("Dane podkategorii")]),_vm._l((_vm.form.navigation_subgroups),function(subgroup,index){return _c('b-card',{key:index,staticClass:"mb-1"},[_c('b-card-title',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_c('h5',{staticClass:"m-0"},[_vm._v(_vm._s(subgroup.name ? subgroup.name : ("Grupa " + (index + 1))))])]),_c('div',{staticClass:"d-flex"},[_c('span',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(("sub-" + index)),expression:"`sub-${index}`"}],class:("sub-" + index)},[_c('feather-icon',{staticClass:"edit-icon",attrs:{"icon":"EditIcon"}})],1),_c('span',[_c('feather-icon',{staticClass:"delete-icon ml-1",attrs:{"icon":"TrashIcon"},on:{"click":function($event){return _vm.removeGroup(index)}}})],1)])]),_c('div',{staticClass:"mt-2"},[_c('b-form-group',{attrs:{"label":"Nazwa grupy","label-for":("v-group-name-" + index)}},[_c('validation-provider',{attrs:{"name":"Nazwa grupy","rules":"required|min:2|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("v-group-name-" + index),"placeholder":"Nazwa grupy"},model:{value:(subgroup.name),callback:function ($$v) {_vm.$set(subgroup, "name", $$v)},expression:"subgroup.name"}}),_c('small',{staticClass:"text-danger",class:errors[0] ? 'error-shown' : ''},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Slug (pozostaw pusty, aby wygenerować automatycznie)","label-for":("v-group-slug-" + index)}},[_c('validation-provider',{attrs:{"name":("Slug grupy " + (index + 1)),"rules":"min:2|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("v-group-slug-" + index),"placeholder":"Slug"},model:{value:(subgroup.slug),callback:function ($$v) {_vm.$set(subgroup, "slug", $$v)},expression:"subgroup.slug"}}),_c('small',{staticClass:"text-danger",class:errors[0] ? 'error-shown' : ''},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-collapse',{attrs:{"id":("sub-" + index)}},[_vm._l((subgroup.navigation_elements),function(navEl,subIdx){return _c('div',{key:("sub-" + subIdx),staticClass:"pl-2 mt-5"},[_c('b-card-title',{staticClass:"d-flex justify-content-between align-items-center mt-3"},[_c('div',[_c('h6',{staticClass:"m-0"},[_vm._v(" "+_vm._s(subgroup.name ? subgroup.name : "nazwa grupy")+", "+_vm._s(navEl.name ? navEl.name : 'nazwa kategorii')+" ")])]),_c('div',[_c('feather-icon',{staticClass:"delete-icon",attrs:{"icon":"TrashIcon"},on:{"click":function($event){return _vm.removeCategory(index, subIdx)}}})],1)]),_c('div',{staticClass:"mt-2"},[_c('b-form-group',{attrs:{"label":"Nazwa","label-for":("v-subgroup-name-" + index + "-" + subIdx)}},[_c('validation-provider',{attrs:{"name":("Nazwa grupy " + (index + 1) + ", kategorii " + (subIdx + 1)),"rules":"required|min:2|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("v-subgroup-name-" + index + "-" + subIdx),"placeholder":"Nazwa"},model:{value:(navEl.name),callback:function ($$v) {_vm.$set(navEl, "name", $$v)},expression:"navEl.name"}}),_c('small',{staticClass:"text-danger",class:errors[0] ? 'error-shown' : '',attrs:{"data-collapse-id":("sub-" + index)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Slug (pozostaw pusty, aby wygenerować automatycznie)","label-for":("v-subgroup-slug-" + index + "-" + subIdx)}},[_c('validation-provider',{attrs:{"name":("Slug grupy " + (index + 1) + ", kateogrii " + (subIdx + 1)),"rules":"min:2|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("v-subgroup-slug-" + index + "-" + subIdx),"placeholder":"Slug"},model:{value:(navEl.slug),callback:function ($$v) {_vm.$set(navEl, "slug", $$v)},expression:"navEl.slug"}}),_c('small',{staticClass:"text-danger",class:errors[0] ? 'error-shown' : '',attrs:{"data-collapse-id":("sub-" + index)}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Kategorie","label-for":"v-category-select"}},[_c('validation-provider',{attrs:{"name":("Kategoria " + (index + 1) + "-" + (subIdx + 1)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","name":("Kategoria " + (index + 1) + "-" + (subIdx + 1)),"placeholder":"Wybierz kategorię","id":("v-subgroup-categories-" + index + "-" + subIdx),"clearable":false,"closeOnSelect":false,"multiple":true,"deselectFromDropdown":false,"options":_vm.subcategories},model:{value:(navEl.categories),callback:function ($$v) {_vm.$set(navEl, "categories", $$v)},expression:"navEl.categories"}}),_c('small',{staticClass:"text-danger",class:errors[0] ? 'error-shown' : '',attrs:{"data-collapse-id":("sub-" + index)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1)}),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mt-2 ml-2",attrs:{"size":"sm","variant":"info"},on:{"click":function($event){return _vm.addCategory(index)}}},[_vm._v(" Dodaj kategorię ")])],1)],2)],1)],1)}),_c('div',{staticClass:"d-flex mt-2 justify-content-end"},[_c('b-button',{attrs:{"variant":"info"},on:{"click":_vm.addGroup}},[_vm._v("Dodaj grupę")]),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"success","type":"submit"}},[_vm._v("Zapisz")])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }